import { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";

// import FolderAndFile from "../../FolderAndFile/FolderAndFile";

import { useStore } from "../../../../hook-store/store";

// import { drawDreamerLink } from "../../../../App";

import { marks } from "../../../../utils/marks";
import classes from "./EditLinkModal.module.css";

function EditLinkDreamInpainterIpaImages(props) {
  const {
    ipaImageKeys,
    setIpaImageKeys,
    paramData,
    setParamData,
    targetUrl,
  } = props;

  // console.log('FileActions.js-props', props, object.Key);
  const [t] = useTranslation("translation");

  const [store, dispatch] = useStore();
  const { 
    // editLinkModalData,
    imageUrlList,
   } = store.bucketObjectStore;
  // const { allObjectsMetaList } = store.objectMetadataStore;
  const { gLoading } = store.uiStore;

  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (gLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [gLoading]);

  // const addIpaImageKeysHandler = (file) => {
  //   console.log(file);

  //   const ipaKeys = ipaImageKeys;
  //   const addedKeys = ipaKeys.concat(file.Key);

  //   setIpaImageKeys(addedKeys.slice(0,3));
  // };

  const deleteIpaImaegeKeyHandler = (key) => {
    const deletedKeys = ipaImageKeys.filter(element => {
      return key !== element.key;
    });

    setIpaImageKeys(deletedKeys);

    // const deletedIpaImageKeys = paramData.ipaImageKeys.filter(ele => {
    //   return key !== ele;
    // });

    
    // setParamData({
      //   key: paramData.key,
      //   ipaImageKeys: deletedIpaImageKeys,
      // });

    const deletedIpaImages = paramData.ipaImages.filter(ele => {
      return key !== ele.key;
    });

    setParamData({
      ...paramData,
      ipaImages: deletedIpaImages,
    });
  };

  // const targetUrl = `${drawDreamerLink}/?keyData=${JSON.stringify(paramData)}&imagePhotoUserId=${localStorage.getItem('userId')}&openApp=imageToImageGeneration`

  let editLinkImageStylizerIpaImagesBody;

  if (ipaImageKeys.length === 0) {
    editLinkImageStylizerIpaImagesBody = (
      <div>no image</div>
    )
  }
  if (ipaImageKeys?.length > 0) {
    editLinkImageStylizerIpaImagesBody = (
      <div>
        <ul>{ipaImageKeys.map(element => {
          const imageUrlObj = element.imageUrlObj;
          const smallImageUrl = imageUrlObj?.smallImageUrl;
          
          let dispImageUrl = smallImageUrl;

          if (!smallImageUrl) {
            dispImageUrl = imageUrlObj?.imageUrl;
          }

          return (
            <li key={element.key}
              className={classes.editLinkIpaImageList}
            >
              <div 
              // className={classes.editLinkIpaImageContainer}
              >
                <div>
                  <img className={classes.editLinkIpaImage}
                    // style={{maxHeight:"50px"}}
                    src={dispImageUrl}
                  />
                </div>
                <div>
                  <button
                    style={{padding: "0.25rem"}}
                    onClick={() => { deleteIpaImaegeKeyHandler(element.key) }}
                  >
                    {marks.trashMark} delete
                  </button>
                </div>
              </div>
            </li>
            );
          })}
        </ul>

        <div className={classes.editLinkSection}>
          <a 
            href={targetUrl}
            target="_blank" rel="noreferrer noopener"
            // style={}
          >
            <button className="btnBase">
              open files in Dream Inpainter
            </button>
          </a>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      {/* {isLoading && <div>loading...loading...</div>} */}
      {editLinkImageStylizerIpaImagesBody}
    </Fragment>
  );
}

export default EditLinkDreamInpainterIpaImages;
